 
<template>
  <v-main style="background-color:white">
    <v-container style="height:100vh" 
          class="d-flex justify-center align-center">
      
    <div>
       
        <v-row>
          <v-col>
            <h2 class="ml-3">로그인</h2>
          </v-col>
          
        </v-row>

        <v-row>
          <v-col>
              <!-- <v-btn :disabled="!agreement" @click="googleSignIn" tile
                width="310"
                height="75">
                 <v-img
                    max-height="70"
                    max-width="310"
                    :src="require('@/assets/btn_google_signin.png')"></v-img>
              </v-btn> -->

              <v-btn  @click="googleSignIn"  
                elevation="1"
                width="355"
                height="45"
                
                tile
                class="white--text ml-3"
                color="#E16358">

                <v-icon size="32" color="white" left class="mr-12">mdi-google-plus</v-icon>
                  구글 아이디로 로그인
               </v-btn>

          </v-col>
       
       
             
        </v-row>

        <v-row >
             <v-checkbox  color="#E16358" v-model="agreement" class="ml-5 my-checkbox" label="서비스이용약관과 개인정보처리방침에 동의합니다.">
          </v-checkbox>
        </v-row>

        <div style="width:355px">

          <p class="text-caption mt-5 ml-5">
            Google을 통해 로그인하면 이오스퀘스트의 이용 약관 및 개인 정보 처리 방침에 동의하게 됩니다.
            자세한 [서비스 이용약관] 과 [개인정보처리방침]은 하단의 링크를 클릭하여 상세한 내용을 확인이 가능합니다.
            위에 체크박스를 체크한 후에 구글로그인 버튼을 클릭하여 접속해주세요.
          </p>

        </div> 

         <v-breadcrumbs class="ml-10" :items="items" divider="-">
            
        </v-breadcrumbs>

    </div>
        
    </v-container>
  </v-main>
</template>

<style scope>
.container_4{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center; 
  width: 300px;
  height: 500px;
  margin-top: 20px;
  border:1px solid #000;
  -webkit-justify-content: center;
  justify-content: center;
}
 
.my-checkbox .v-label {
  font-size: 13px;
} 

</style>

<script>

import { GoogleAuthProvider,signInWithPopup,onAuthStateChanged,si } from "firebase/auth";
import { auth } from '@/plugins/firebase';
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/plugins/firebase';

const provider = new GoogleAuthProvider();

export default ({

    data: () => ({
     
      dialog: false,
      agreement:false,
      rules: {
        required: v => !!v || '필수로 체크해야 합니다.',
      },
      items: [
        {
          text: '서비스 이용약관',
          disabled: false,
          href: './Terms',
          target:"_blank"
        },
        {
          text: '개인정보처리방침',
          disabled: false,
          href: './Privacy',
          target:"_blank"
        },
      ],
    }),
  
  methods: {
    

    googleSignIn(){
  
      if(this.agreement == false)
      {
        alert('서비스이용약관및 개인정보처리방침에 동의해주세요')
        return;
      }
      
      signInWithPopup(auth, provider);
    },

    init()
        {

          onAuthStateChanged(auth, async (user) =>  {
           
            this.$store.state.firebaseLoginUserInfo = user;

            if(user== null)
            {
              this.$store.state.isFirebaseAdmin = false;
            }
            else{
                
              const docRef = doc(db, "admins", user.auth.lastNotifiedUid);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                   
                this.$store.state.isFirebaseAdmin = true;
              } else {
                
                this.$store.state.isFirebaseAdmin = false;
              }

              this.$router.push('/profile') 
             
            }
              
          });
          
        }

  },
  mounted() {
     this.init();
  },
})
</script>
